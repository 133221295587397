import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import ApplicationForm from "../components/careers/application-form";

export default function Page({ data }) {
  const job = data.wpJobPost;
  return (
    <Layout>
      <Seo seo={job.seo} />

      <div className="wysiwyg__content__wrapper">
        <h3 className="job__heading__wrapper text-blue">{job.title}</h3>

        <div className="job__section">
          <p className="wysiwyg__content">
            <strong className="text-blue">Job title: </strong>
            {job.jobPost?.summary?.jobTitle}
          </p>
          <p className="wysiwyg__content">
            <strong className="text-blue">Direct report: </strong>
            {job.jobPost?.summary?.directReport}
          </p>
        </div>

        <div className="job__section">
          <p className="text-blue job__section-title">Job purpose:</p>
          <div
            className="wysiwyg__content"
            dangerouslySetInnerHTML={{ __html: job?.jobPost?.jobPurpose }}
          />
        </div>

        <div className="job__section">
          <p className="text-blue job__section-title">Key responsibilities:</p>
          <div
            className="wysiwyg__content"
            dangerouslySetInnerHTML={{
              __html: job?.jobPost?.keyResponsibilities,
            }}
          />
        </div>

        <div className="job__section">
          <p className="text-blue job__section-title">Job context:</p>
          <div
            className="wysiwyg__content"
            dangerouslySetInnerHTML={{
              __html: job?.jobPost?.jobContext,
            }}
          />
        </div>

        <div className="job__section">
          <p className="text-blue job__section-title">Job profile:</p>
          <div
            className="wysiwyg__content"
            dangerouslySetInnerHTML={{ __html: job?.jobPost?.jobProfile }}
          />
        </div>

        <p className="text-blue job__section-title">Apply</p>

        <div className="job__section--application">
          <div className="job__application__wrapper">
            <ApplicationForm jobTitle={job.title} />
          </div>
        </div>

        <div className="mb-10" />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($databaseId: Int!) {
    wpJobPost(databaseId: { eq: $databaseId }) {
      uri
      title
      databaseId
      jobPost {
        jobContext
        jobProfile
        jobPurpose
        keyResponsibilities
        summary {
          jobTitle
          directReport
        }
      }
      seo {
        fullHead
      }
    }
  }
`;
